<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fa">
        <li class="left">
          <router-link to="/login">
            <i class="iconfont icon-fanhui c-999 icon-fanhui"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main main_reg log_in">
      <h1 class="reg_title">
        {{ t("common.title.registration") }}
        <span v-if="locale==='es'">
          {{
            t('site.slogan')
          }}
        </span>
        <span v-if="locale==='en' || locale==='pb'">
          {{
            sysDataDictionaryWbObj?.WZMC?.dataContent + ' ' +
            t('site.slogan')
          }}
        </span>
      </h1>

      <div class="box">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="blur"
          @submit.prevent="handleSubmit"
          autocomplete="off"
        >
          <ul>
            <li class="li-code">
              <b>
                {{
                  "+" +
                    (sysDataDictionaryWbObj.ZCQHPZ &&
                      sysDataDictionaryWbObj.ZCQHPZ.dataContent) || ""
                }}
              </b>
              <AFormItem name="mobile" class="border01">
                <AInput
                  v-model:value="formModel.mobile"
                  :maxlength="11"
                  :placeholder="t('account.placeholder.mobile')"
                />
              </AFormItem>

              
            </li>
         
            <li>
              <AFormItem name="password">
                <AInput
                  type="password"
                  v-model:value="formModel.password"
                  :placeholder="t('account.placeholder.password')"
                />
              </AFormItem>
            </li>

            <li>
              <AFormItem name="checkPass">
                <AInput
                  type="password"
                  v-model:value="formModel.checkPass"
                  :placeholder="t('account.placeholder.confirmPassword')"
                />
              </AFormItem>
            </li>

            <li>
              <AFormItem name="inviteCode">
                <AInput
                  :maxlength="8"
                  v-model:value="formModel.inviteCode"
                  :placeholder="t('account.placeholder.inviteCode')"
                />
              </AFormItem>
            </li>

            <li>
              <AFormItem name="username">
                <AInput
                  v-model:value="formModel.username"
                  :placeholder="t('account.placeholder.nickname')"
                />
              </AFormItem>
            </li>
          </ul>
          
          <AFormItem v-if="locale !=='es'">
            <div class="agreement">
              <label>
                <input v-model="agreement" type="checkbox" />
                <i class="iconfont"></i>
              </label>
              <div class="text">
                {{ t("account.signup.terms.pre") }}
                <span @click="popupAgreement()">
                  {{ t("account.signup.terms.link") }}
                </span>
                {{ t("account.signup.terms.post") }}
              </div>
            </div>
          </AFormItem>
          <br /><br />
          <AFormItem>
            <div class="Sys_submit_btn register pb-60">
              <button type="submit">{{ t("common.button.signup") }}</button>
            </div>
          </AFormItem>
        </AForm>
      </div>
    </div>
  </div>

  <Loading :loading="loading" />

  <AModal
    v-model:visible="visible"
    :footer="null"
    centered
    :title="t('common.title.termOfService')"
    wrapClassName="Sys_Popup"
    width="85%"
  >
    <div class="Sys_Popup_wrap">
      <TermOfService />
    </div>

    <div class="Sys_submit_btn">
      <button type="button" @click="handleConfirm">
        {{ t("common.button.confirm") }}
      </button>
    </div>
  </AModal>
</template>
<script>
import { ref, reactive, getCurrentInstance } from "vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import route from "@/router";
import Loading from "@/components/Loading.vue";
import { useI18n } from "@/lang";
import TermOfService from "@/views/TermOfService";
import { RE_PHONE_NUMBER } from "@/utils/constant";
import api from "@/api";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { Loading, TermOfService },
  setup() {
    const { t,locale } = useI18n();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const formRef = ref(null);
    const formModel = reactive({
      mobile: "",
      password: "",
      checkPass: "",
      username: "",
      inviteCode: "",
      // lineId: '',
    });

    formModel.inviteCode = route?.currentRoute?.value?.query?.key;

    let loading = ref(false);
    let isSend = ref(false);
    let agreement = ref(false);
    let visible = ref(false);
    let cooldown = ref(0);
    let checkMobile = ref(true);

    function handleConfirm() {
      visible.value = false;
    }

    function popupAgreement() {
      visible.value = true;
    }

    let validatePass = async (rule, value) => {
      if (value !== formModel.password) {
        return Promise.reject(t("error.validator.checkPass.notMatch"));
      } else {
        return Promise.resolve();
      }
    };

    const ruleRef = reactive({
      mobile: [
        {
          required: true,
          message: t("error.validator.mobile.required"),
          trigger: "change",
        },
        {
          validator: async (rule, value) => {
            const check = RE_PHONE_NUMBER.test(value);
            checkMobile.value = !check;
            // console.log(value,value.length,(value.length !== 10 && value.length !== 11))
            // if(value.length !== 10 && value.length !== 11){
            //   return Promise.reject(t("error.validator.mobile.errorlength"));
            // }
            // console.log(value,value.length,'-----------------2')
            if(value.length < 8 || value.length > 11){
              return Promise.reject(t("error.validator.mobile.errorlength"));
            }
            if (value[0] === "0") {
              return Promise.reject(t("error.validator.mobile.startWith0"));
            }
            if (check) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("error.validator.mobile.invalid"));
            }
          },
        },
      ],
      // identifyCode: [
      //   {
      //     required: true,
      //     message: t("error.validator.identifyCode.required"),
      //     trigger: "change",
      //   },
      // ],
      password: [
        {
          required: true,
          message: t("error.validator.password.required"),
          trigger: "change",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
      checkPass: [
        {
          validator: validatePass,
        },
        {
          required: true,
          message: t("error.validator.checkPass.required"),
          trigger: "change",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
      inviteCode: [
        {
          required: true,
          message: t("error.validator.inviteCode.required"),
          trigger: "change",
        },
        {
          validator: (_rule, inviteCode) => {
            if (/^[A-Z0-9]{6,}$/.test(inviteCode)) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("error.validator.inviteCode.invalid"));
            }
          },
        },
      ],
      username: [
        {
          required: true,
          message: t("error.validator.nickname.required"),
          trigger: "change",
        },
        {
          validator: (_rule, username) => {
            if (username && username.length >= 4) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("error.validator.nickname.length"));
            }
          },
        },
      ],
      // lineId: [
      //   {
      //     required: true,
      //     message: t('error.validator.lineId.required'),
      //   },
      //   {
      //     min: 6,
      //     message: t('error.validator.lineId.length'),
      //   },
      // ],
    });

    async function sendIdentifyCode(mobile) {
      let res = await api.sendSms(mobile);
      if (!(res && res.data && res.data.success)) {
        return;
      }
      let sendIdentifyCode = res.data;
      cooldown.value = res.data.data;

      return sendIdentifyCode.success
        ? Promise.resolve()
        : Promise.reject(t("account.signup.smsFailed"));
    }

    function sendCode(mobile) {
      if (formModel.mobile[0] === "0") {
        message.error(t("error.validator.mobile.startWith0"));
        return;
      }
      if (!formModel.mobile) {
        message.error(t("error.validator.mobile.required"));
        return;
      }
      if (!RE_PHONE_NUMBER.test(mobile)) {
        return;
      }

      sendIdentifyCode(mobile).then(() => {
        isSend.value = true;
        let cooldownTimer = setInterval(() => {
          cooldown.value--;
          if (cooldown.value <= 0) {
            clearInterval(cooldownTimer);
          }
        }, 1000);
      });
    }

    async function handleSubmit() {
      
      if(locale.value === 'es'){
        agreement.value = true
      }
      if (!agreement.value) {
        message.info(t("error.signup.termOfService"));
        return;
      }
      if (loading.value) {
        return;
      }
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      try {
        loading.value = true;
        let reqData = {
          invitationCode: formModel.inviteCode,
          passWord: formModel.password,
          // smsCode: formModel.identifyCode,
          userAccount: formModel.mobile,
          userNick: formModel.username,
        };
        let res = await api.regist(reqData);
        if (res.data.success) {
          await router.replace("/login");
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      visible,
      handleConfirm,
      formRef,
      formModel,
      ruleRef,
      handleSubmit,
      checkMobile,
      sendCode,
      cooldown,
      agreement,
      popupAgreement,
      loading,
      sysDataDictionaryWbObj,
      locale
    };
  },
};
</script>
<style lang="scss" scoped>
.ant-input{
  // padding: 0 0 0 5px;
  border:1px solid #FF467C;
}
li{
  margin-bottom: 10px;
}
input{
  text-indent: 10px;
}
</style>
