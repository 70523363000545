<template>
  <div class="wrap">
    <div class="main login">
      <div class="logo">
        <img
          :src="
            sysDataDictionaryWbObj.TXHQDZ?.dataContent +
            sysDataDictionaryWbObj.SJDLOGO?.dataContent
          "
        />
      </div>
      <div class="box">
        <AForm
          layout="vertical"
          ref="formRef"
          :model="formModel"
          @submit.prevent="handleLogin"
          :rules="ruleRef"
          validateTrigger="blur"
        >
          <AFormItem name="mobile">
            <i class="iconfont icon-shouji"></i>
            <b>{{
              "+" +
                (sysDataDictionaryWbObj.ZCQHPZ &&
                  sysDataDictionaryWbObj.ZCQHPZ.dataContent) || ""
            }}</b>
            <AInput
              :maxlength="15"
              v-model:value="formModel.mobile"
              :placeholder="t('account.placeholder.mobile')"
            />
          </AFormItem>

          <AFormItem name="password">
            <i class="iconfont icon-mima"></i>
            <a-input-password v-model:value="formModel.password" class="bor1" :placeholder="t('account.placeholder.password')" />
            <!-- <AInput
              type="password"
              v-model:value="formModel.password"
              :placeholder="t('account.placeholder.password')"
            /> -->
          </AFormItem>
          <AFormItem class="login_page tologin">
            <AButton html-type="submit">{{ t("common.button.login") }}</AButton>
          </AFormItem>
        </AForm>
      </div>
      <div class="links">
        <router-link to="/Register">
          {{ t("common.button.register") }} </router-link
        >
        <!-- | -->
        <!-- <router-link to="/ResetPassword">
          {{ t("common.button.forgotPassword") }}
        </router-link> -->
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "@/lang";
import api from "@/api";
import { RE_PHONE_NUMBER } from "@/utils/constant";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import Loading from "@/components/Loading.vue";
export default {
  name: "Login",
  components: {Loading},
  setup(prop, context) {
    console.log(prop, context);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const formRef = ref(null);
    const loading = ref(false);
    const formModel = reactive({
      mobile: "",
      password: "",
    });

    const router = useRouter();

    const ruleRef = reactive({
      mobile: [
        {
          required: true,
          message: t("error.validator.mobile.required"),
          trigger: "change",
        },
        {
          validator: async (rule, mobile) => {
            if (!RE_PHONE_NUMBER.test(mobile)) {
              return Promise.reject(t("error.validator.mobile.invalid"));
            }
            let isMobileExists = true;
            // let {
            //   data: { isMobileExists }
            // } = {}

            if (isMobileExists) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("error.validator.mobile.notExists"));
            }
          },
        },
      ],
      password: [
        {
          required: true,
          message: t("error.validator.password.required"),
          trigger: "change",
        },
        // {
        //   min: 6,
        //   max: 30,
        //   message:
        //     t("extra.minLength", { min: 6 }) +
        //     t("extra.maxLength", { max: 30 }),
        //   trigger: "blur",
        // },
      ],
    });

    async function handleLogin() {
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      // let reqData = {
      //   passWord: formModel.password,
      //   userAccount:
      //     ((sysDataDictionaryWbObj.value.ZCQHPZ &&
      //       sysDataDictionaryWbObj.value.ZCQHPZ.dataContent) ||
      //       "") + formModel.mobile,
      // };
      let reqData = {
        passWord: formModel.password,
        userAccount: formModel.mobile,
      };
      try {
        loading.value = true;
        let res = await api.login(reqData);
        if (res.data.success) {
          await router.replace("/home");
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      formModel,
      handleLogin,
      sysDataDictionaryWbObj,
      ruleRef,
      formRef,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped>
// .login .box{
//   padding-top:15%;
// }
.bor1{
  border:none !important;
}
.ant-input-affix-wrapper{
  width: 92%;
}
</style>

