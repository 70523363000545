<template>
  <div class="Profile">
    <Loading :loading="loading" />
    <div class="one">
      <div class="userinfo" v-if="userDetail">
        <ul>
          <h2>
            <StaticImage
              :url="'Head/' + userDetail.avatarAddress + '.png'"
              @click="showAvatarModal"
            ></StaticImage>
          </h2>
          <h3>
            <span>{{ userDetail.userNick }}</span>
            <VipLevel v-if="sysDataDictionaryWbObj?.VIPSHOW?.dataOnOff" :level="userDetail.levelName?.slice(3)" />
          </h3>
          <h3>{{ userDetail.userAccount }}</h3>
          <li>
            <router-link to="/profile/user_info">
              <b class="iconfont icon-jilu"></b>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="one_box">
        <div class="money" v-if="userDetail">
          <ol>
            <li>
              {{ t("profile.label.walletBalance") }}:
              <b>{{ formatMoney(userDetail.amount) }}</b>
            </li>
            <li>
              <router-link to="/recharge">
                {{ t("common.title.recharge") }}
              </router-link>
              <router-link to="/withdraw">
                {{ t("common.title.withdraw1") }}
              </router-link>
            </li>
          </ol>
          <!-- <ol>
            <li>
              {{ t("profile.label.commission") }}:
              <b>{{ formatMoney(userDetail.commission) }}</b>
            </li>
            <li>
              <router-link to="/recharge">
                {{ t("common.title.recharge") }}
              </router-link>
              <router-link to="/profile/WithdrawalCommission"  style="width:auto;min-width: 100px;">
                &nbsp;&nbsp;{{ t("common.title.withdraw2") }}&nbsp;&nbsp;
              </router-link>
            </li>
          </ol> -->
          <!-- <ul>
            <li>
              {{ t("home.experienceGold") }}
              <b>{{ formatMoney(userDetail.experienceGold) }}</b>
            </li>
            <li>
              {{ t("home.todayEarnings") }}
              <b>{{ formatMoney(userDetail.toDayIncome) }}</b>
            </li>
            <li>
              {{ t("home.cumulativeIncome") }}
              <b>{{ formatMoney(userDetail.totalIncome) }}</b>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="two">
      <div class="box">
        <!-- <router-link to="/grab_order">
          <p>
            <b class="iconfont icon-jilu2"></b>
            <span>{{ t("profile.menu.orderRecord") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link> -->

        <router-link to="/Account">
          <p>
            <b class="iconfont icon-yinhangka2"></b>
            <span>{{ t("profile.menu.accountDetails") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>
        <router-link to="/recharge_list">
          <p>
            <b :class="locale==='es'?'iconfont icon-meiyuanzhuanru':'iconfont icon-shouru2'" ></b>
            <span>{{ t("profile.menu.rechargeRecord") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>
        <router-link to="/withdrawal_list">
          <p>
            <b :class="locale==='es'?'iconfont icon-meiyuanzhuanchu':'iconfont icon-zhuanchu2'" ></b>
            <span>{{ t("profile.menu.withdrawRecord") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>
        
        
        <router-link to="/about">
          <p>
            <b class="iconfont icon-zhuyi"></b>
            <span>{{ t("profile.menu.about") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>
        <!-- <router-link to="/downlaod">
          <p>
            <b class="iconfont icon-xiazai"></b>
            <span>{{ t("profile.menu.download") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link> -->
        
        
        <!-- <router-link to="/agencyCenter">
          <p>
            <b class="iconfont icon-dailishang"></b>
            <span>{{ t("profile.menu.agencyCenter") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link> -->

        <router-link to="/service" v-if="locale==='ind'">
          <p>
            <b class="iconfont icon-kefu2"></b>
            <span>{{ t("profile.menu.service") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>

        <router-link to="/inviteFriends">
          <p>
            <b class="iconfont icon-shuju"></b>
            <span>{{ t("profile.menu.inviteFriends") }}</span>
            <i class="iconfont icon-you2"></i>
          </p>
        </router-link>
        
        
      </div>
    </div>

    <AModal
      centered
      :mask="true"
      :maskClosable="false"
      v-model:visible="avatarModal"
      @ok="changeAvatar"
      :zIndex="9999"
      width="85%"
      wrapClassName="Sys_Popup"
      :title="t('home.ChooseYourAvatar')"
      :okText="t('common.button.ok')"
      :cancelText="t('common.button.cancel')"
    >
      <div class="Sys_Popup_wrap">
        <a-radio-group v-model:value="chooseAvatar" name="radioGroup">
          <a-radio v-for="(item, index) in 33" :key="index" :value="'' + index">
            <StaticImage
              :style="{
                width: '30px',
                height: '30px',
                borderRadius: '30px',
                marginTop: '5px',
                marginBottom: '5px',
              }"
              :url="'Head/' + index + '.png'"
            ></StaticImage>
          </a-radio>
        </a-radio-group>
      </div>
    </AModal>
  </div>
</template>
<script>
import { ref} from "vue";
import { formatMoney } from "@/utils/formatter";
import VipLevel from "@/components/VipLevel.vue";
import StaticImage from "@/components/StaticImage.vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useI18n } from "@/lang";
import { userDetail } from "@/hooks/userDetail";
import api from '@/api'
import Loading from "@/components/Loading.vue";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { VipLevel, StaticImage,Loading },
  setup() {
    const { t,locale } = useI18n();
    const loading = ref(false);
    // const unreadMessageCount = inject("unreadMessageCount");
    const router = useRouter();
    let avatarModal = ref(false);
    let chooseAvatar = ref("0");
    api.angencyTeamReport({})
    api.getUserDetails();
    function handleLogout() {
      loading.value = true
      api.logout()
        .then(async () => {
          loading.value = false
          router.replace("/login");
        })
        .catch((err) => {
          loading.value = false
          console.log(err);
          message.info("logout failed");
        });
    }
    async function changeAvatar() {
      let res = await api.updateAvatar(chooseAvatar.value);
      if (res && res.data && res.data.success) {
        avatarModal.value = false;
        api.getUserDetails();
      }
    }

    function showAvatarModal() {
      avatarModal.value = true;
      chooseAvatar.value = userDetail.value.avatarAddress;
    }
    return {
      t,
      locale,
      VipLevel,
      // unreadMessageCount,
      formatMoney,
      handleLogout,
      userDetail,
      changeAvatar,
      showAvatarModal,
      chooseAvatar,
      sysDataDictionaryWbObj,
      avatarModal,
      loading
    };
  },
};
</script>
<!-- <style lang="scss" scoped>
.abtn{
  width:150px;
}
</style> -->