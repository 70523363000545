<template>
  <div v-if="locale==='en'" class="box">
    Terms
    <br />
    Welcome to {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!
    <br />
    The technology and services provided by
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} are designed to help people
    interact, use their free time to make money, provide a platform for help,
    and develop business. These terms of service apply to your use of
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} and other products,
    functions, applications, services, technologies and software (collectively
    referred to as {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} or products)
    provided by us, unless we clearly stipulate that other terms (not the terms
    of this article) apply. These products are provided by
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}.
    <br />
    Your use of {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} or other
    products and services described in this article is free. By using our
    products, you agree to what we can show you.
    <br />
    We keep your personal information confidential and will not sell your
    personal data to others.
    <br />
    <br />
    1. Services we provide
    <br />
    Our mission is to create more jobs, let many people get rich, and help poor
    people improve their lives. We provide you with products and services:
    <br />
    Research ways to improve our services:
    <br />
    We will participate in research activities to develop, test and improve our
    products. This includes analyzing the user data we collect and understanding
    how users use our products, such as conducting surveys, testing and
    troubleshooting new features. The data usage policy explains how we use data
    to support research to develop and improve our services.
    <br />
    To provide users with our services on a global scale:
    <br />
    To operate global services, we need to store and distribute content and data
    in data centers and systems around the world (including outside the
    country/region where you live). This infrastructure may be operated or
    controlled by {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Limited or its
    affiliates.
    <br />
    <br />
    2. Your commitment to {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    <br />
    We provide these services to you and others to help advance our mission. In
    exchange, we need you to make the following commitments:
    <br />
    1. Who can use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    <br />
    When people carry out activities with their real identities,
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    will be safer and people will be more responsible for their actions.
    Therefore, we require every user:
    <br />
    Use names from everyday life.
    <br />
    Provide accurate information about yourself.
    <br />
    Only create an account (for personal use), and only use your timeline for
    personal purposes.
    <br />
    Do not disclose your account password to others, do not allow others to use
    your own {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} account, and do not
    transfer your own account to others without our permission.
    <br />
    We try our best to make
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} available to everyone, but
    you must not use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} in the
    following situations:
    <br />
    1. Under 13 years old (or under the minimum legal age for using our products
    in your country/region).
    <br />
    2. You are a convicted sex offender
    <br />
    3. Your account has been disabled by us for violating our terms or policies
    <br />
    <br />
    We have the right to restrict users who violate these regulations from using
    the APP or restrict their access. If you seriously or repeatedly violate the
    terms of this article; taking the foregoing actions may expose us or other
    parties to legal liabilities, may damage
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} users, may damage or affect
    the integrity or operation of any services, systems or products we provide;
    Restricted by technical limitations; or the law prohibits us from taking the
    aforementioned actions.
    <br />
    2. Permission granted to us by you
    <br />
    We need you to grant us specific permissions to provide services to you:
    <br />
    1. Investigate or determine illegal activities or violations of our terms
    and policies (for example: determine or investigate violations of our
    products or systems);
    <br />
    2. Fulfill legal obligations, such as retaining evidence;
    <br />
    3. Comply with the requirements of judicial or administrative departments,
    law enforcement departments or government agencies;
    <br />
    <br />
    1. In this case, the retention period of the relevant content will not
    exceed the necessary retention period determined according to the purpose of
    content retention, and the exact retention period depends on the situation.
    <br />
    2. Update the permissions of the software you use or download: You download
    or use our software, which means granting us a license to allow us to
    download and install the update for you when the software update is
    available.
    <br />
    <br />
    3. Additional terms
    <br />
    1. Account freeze or suspension
    <br />
    We hope that {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} is a place
    where people feel safe. If we determine that you have violated our terms or
    policies clearly, severely, or repeatedly, we will temporarily suspend or
    permanently disable your account.
    <br />
    2. Limitation of Liability
    <br />
    We try our best to provide users with the best products and provide clear
    guidelines for each user. However, we only provide products as they are, so
    we do not guarantee that the products will continue to be safe, reliable,
    and error-free, nor that the operation of the products will not be
    interrupted, delayed or imperfect. To the extent permitted by law, we also
    refuse to acknowledge all express or implied warranties, including implied
    warranties of merchantability, suitability for specific purposes, ownership
    and non-infringement. We cannot predict when there will be problems with our
    products. Accordingly, our liability shall be limited to the maximum extent
    permitted by applicable laws; for any loss of profit, income, information or
    data arising from or related to this clause or
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} products, or consequential,
    special, indirect, disciplinary Sexual, punitive or collateral damages, even
    if we have been notified that such damages may occur, we will not be liable.
    <br />
    3. Disputes
    <br />
    We try to provide clear rules to reduce or try to avoid disputes between you
    and us. However, if a dispute does occur, it is helpful to know in advance
    where to resolve the dispute and the applicable law. If you are a consumer,
    the laws of your country of residence will apply to any claims, litigation
    or disputes (collectively referred to as “claims”) brought against us in
    relation to these terms or
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} products, and you may have
    jurisdiction over these claims Any competent court in the country/region of
    jurisdiction seeks a solution. In all other cases, you agree that these
    claims will only be resolved in the U.S. District Court for the Northern
    District of California or the state courts in San Mateo County. You also
    agree that your litigation regarding any of these claims is subject to the
    personal jurisdiction of the aforementioned courts, and that California law
    will apply to these terms and any claims, regardless of conflict of laws
    principles.
    <br />
    4. Other
    <br />
    1. This clause (formerly known as the "Statement of Rights and Liability")
    constitutes the complete agreement between you and
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} regarding the use of our
    products. This clause supersedes any previous agreement.
    <br />
    2. Some products we provide are also subject to supplementary terms. If you
    use any of these products, we will provide supplementary terms and they will
    be part of the agreement between us and you. For example, if you use our
    products for commercial or business purposes, such as buying advertisements,
    selling products, developing applications, managing your company's
    management team or public homepage, or using our effectiveness measurement
    service, you must agree to our business terms. If you publish or share
    content with music, you must comply with our music usage guidelines. If any
    supplementary terms conflict with these terms, the supplementary terms shall
    prevail with regard to the conflicting terms.
    <br />
    3. If any part of this clause is deemed unenforceable, the validity of the
    remaining parts will not be affected. Our failure to implement any part of
    these terms should not be considered as a waiver of our rights. Any
    modification or waiver of these terms must be in writing and signed by us.
    <br />
    4. Without our consent, you may not transfer any rights or obligations under
    these terms to any third party.
    <br />
    5. You can designate a person (referred to as a "delegated contact") to
    manage your account when it becomes a mourning account. Only your entrusted
    contact person or the person you have explicitly agreed in a valid will or
    similar document to disclose your content to them after death or incapacity
    can request the disclosure of account content to them after your account
    becomes a mourning account.
    <br />
    This clause does not grant any third-party beneficiary rights. We have the
    right to freely transfer all our rights and obligations under these terms
    due to mergers, acquisitions, asset sales or enforcement of legal or other
    reasons.
    <br />
    Device Information
    <br />
    As described below, we collect information from computers, mobile phones,
    smart TVs, and other networked devices that you use and integrate our
    products, and information about these devices, and merge this information on
    the different devices you use. For example, we use the collected information
    about your use of our products on your mobile phone to better customize the
    content (including advertisements) that you can see when you use our
    products on other devices such as laptops or tablets. Function, or to
    evaluate whether you will respond to and take action on the ads you see on
    your phone on other devices.
    <br />
    The information we obtain from these devices includes:
    <br />
    Device attributes: such as operating system, hardware and software version,
    battery power, signal strength, available storage space, browser type,
    application and file name and type, and plug-in information.
    <br />
    Device operation: Information about the operations and behaviors performed
    by the user on the device, such as whether the window is set as the
    foreground or the background, or the movement of the mouse (this helps
    distinguish between manual operations and machine operations).
    <br />
    Identification information: unique identifiers, device numbers, and other
    identification information (such as information obtained from the games you
    play, applications or accounts you use), and home device numbers (or other
    information related to {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}’s
    Unique identification information for the product).
    <br />
    Device signal: Bluetooth signal and information about surrounding Wi-Fi
    access points, beacons and cell phone towers.
    <br />
    Information from device settings: Information that you allow us to receive
    through the device settings you turn on, such as access to your GPS
    location, camera, or photos.
    <br />
    Network and connection: For example, information such as your mobile
    operator or ISP name, language, time zone, mobile phone number, IP address,
    connection speed, etc., and in some cases, information about other devices
    on or near your network, so that We are able to perform various operations,
    such as helping you to cast videos from your phone to your TV.
    <br />
    Cookie data: Data obtained from cookies stored on your device, including
    cookie ID and settings. Users can learn more about how we use cookies in the
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Cookie Policy.
    <br />
    Location-related information: We use location-related information (such as
    your current location, where you live, where you want to go, and businesses
    and people near you) to provide, customize, and improve our products to you
    and others ( Including advertising). Location-related information may be
    based on: accurate device location information (if you allow us to collect
    it), IP address, and information about you and others using
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    products (such as signing in or participating in events).
    <br />
    Product research and development: We use the information we have to develop,
    test and improve our products, including conducting surveys and research,
    testing and troubleshooting new products and functions.
    <br />
    Provide effectiveness measurement, analysis and other business services.
    <br />
    We use the information we have (including your activities outside of our
    products, such as websites and advertisements browsed) to help advertisers
    and other partners evaluate the effectiveness and coverage of their
    advertisements and services, and to understand the people who use their
    services Types and how people interact with their websites, apps, and
    services.
    <br />
    Strengthen user security, data security and product reputation.
    <br />
    We use the information we have to verify accounts and activities, combat
    harmful behavior, detect and prevent spam and other bad experiences,
    maintain the credibility of our products, and enhance user safety and data
    security inside and outside
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} products. For example, we
    use the data we have to investigate suspicious activity or violations of our
    terms or policies, or to detect when users need help.
    <br />
    Communicate with you.
    <br />
    We will use the information we have to send you marketing information,
    communicate with you about our products, and let you understand our policies
    and terms. When you contact us, we will also use your information to provide
    a response.
    <br />
    If this policy changes, how will we notify you?
    <br />
    We will notify you before revising this policy so that you can view the
    revised policy before choosing to continue using our products.
    <br />
  </div>
  
  <div v-if="locale==='pb'" class="box">
    Termos

    <br />

    Bem-vindos a {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!!

    <br />

    A tecnologia e os serviços fornecidos por
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! são projetados para ajudar
    as pessoas a interagir, usar seu tempo livre para ganhar dinheiro, fornecer
    uma plataforma for help, and develop business. Esses termos de serviço se
    aplicam ao seu uso

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! e outros produtos, funções,
    aplicações, serviços, tecnologias e software (coletivamente chamados

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! ou produtos) fornecidos por
    nós, a menos que claramente estipulam que outros termos (não os termos deste
    artigo) se aplicam. Esses products are provided by
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!.

    <br />

    Seu uso de {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! ou outros
    produtos e serviços descritos em este artigo é gratuito. Usando nossos
    produtos, você concorda com o que podemos mostrar você.

    <br />

    Manteremos sua informação pessoal confidencial e não venderemos sua dados
    pessoais para outros.

    <br />

    <br />

    1. Serviços que fornecemos

    <br />

    Nossa missão é criar mais empregos, deixar muitas pessoas ficarem ricos, e
    ajudar os pobres as pessoas melhoram suas vidas. Nós fornecemos produtos e
    serviços:

    <br />

    Modos de pesquisa para melhorar nossos serviços:

    <br />

    Nós participaremos em atividades de pesquisa para desenvolver, testar e
    melhorar produtos. This includes analyzing the user data we collect and
    understanding como os usuários usam nossos produtos, como realizar
    pesquisas, testes e problematizando novas características. A política de uso
    de dados explica como usamos dados para apoiar pesquisas para desenvolver e
    melhorar nossos serviços.

    <br />

    Para fornecer aos usuários nossos serviços em escala global:

    <br />

    Para operar serviços globais, precisamos armazenar e distribuir conteúdo e
    dados em centros de dados e sistemas em todo o mundo (incluindo fora do
    país/região onde você vive). Essa infraestrutura pode ser operada ou
    controlado por {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! Limited or
    its affiliates.

    <br />

    <br />

    2. Seu compromisso com {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!

    <br />

    Nós fornecemos esses serviços a vocês e outros para ajudar a avançar nossa
    missão. Em troca, precisamos que assumam os seguintes compromissos:

    <br />

    1. Who can use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!

    <br />

    Quando as pessoas realizam atividades com suas identidades reais,

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! serão mais seguras e as
    pessoas serão mais responsáveis por suas ações. Portanto, precisamos de cada
    usuário:

    <br />

    Usa nomes da vida cotidiana.

    <br />

    Fornecer informações precisas sobre si mesmo.

    <br />

    Só criar uma conta (para uso pessoal), e apenas usar seu horário para
    propósitos pessoais.

    <br />

    Não divulgue a senha de sua conta a outros, não permita que outros utilizem
    seu próprio {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! conta, e não
    transfera sua própria conta para outros sem nossa autorização.

    <br />

    Tentamos o nosso melhor para fazer
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! disponíveis para todos, mas
    você não devem usar {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! in the
    following situations:

    <br />

    Termos

    <br />

    Bem-vindos a {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!!

    <br />

    A tecnologia e os serviços fornecidos por
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! são projetados para ajudar
    as pessoas a interagir, usar seu tempo livre para ganhar dinheiro, fornecer
    uma plataforma for help, and develop business. Esses termos de serviço se
    aplicam ao seu uso

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! e outros produtos, funções,
    aplicações, serviços, tecnologias e software (coletivamente chamados

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! ou produtos) fornecidos por
    nós, a menos que claramente estipulam que outros termos (não os termos deste
    artigo) se aplicam. Esses products are provided by
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!.

    <br />

    Seu uso de {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! ou outros
    produtos e serviços descritos em este artigo é gratuito. Usando nossos
    produtos, você concorda com o que podemos mostrar você.

    <br />

    Manteremos sua informação pessoal confidencial e não venderemos sua dados
    pessoais para outros.

    <br />

    <br />

    1. Serviços que fornecemos

    <br />

    Nossa missão é criar mais empregos, deixar muitas pessoas ficarem ricos, e
    ajudar os pobres as pessoas melhoram suas vidas. Nós fornecemos produtos e
    serviços:

    <br />

    Modos de pesquisa para melhorar nossos serviços:

    <br />

    Nós participaremos em atividades de pesquisa para desenvolver, testar e
    melhorar produtos. This includes analyzing the user data we collect and
    understanding como os usuários usam nossos produtos, como realizar
    pesquisas, testes e problematizando novas características. A política de uso
    de dados explica como usamos dados para apoiar pesquisas para desenvolver e
    melhorar nossos serviços.

    <br />

    Para fornecer aos usuários nossos serviços em escala global:

    <br />

    Para operar serviços globais, precisamos armazenar e distribuir conteúdo e
    dados em centros de dados e sistemas em todo o mundo (incluindo fora do
    país/região onde você vive). Essa infraestrutura pode ser operada ou
    controlado por {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! Limited or
    its affiliates.

    <br />

    <br />

    2. Seu compromisso com {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!

    <br />

    Nós fornecemos esses serviços a vocês e outros para ajudar a avançar nossa
    missão. Em troca, precisamos que assumam os seguintes compromissos:

    <br />

    1. Who can use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!

    <br />

    Quando as pessoas realizam atividades com suas identidades reais,

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! serão mais seguras e as
    pessoas serão mais responsáveis por suas ações. Portanto, precisamos de cada
    usuário:

    <br />

    Usa nomes da vida cotidiana.

    <br />

    Fornecer informações precisas sobre si mesmo.

    <br />

    Só criar uma conta (para uso pessoal), e apenas usar seu horário para
    propósitos pessoais.

    <br />

    Não divulgue a senha de sua conta a outros, não permita que outros utilizem
    seu próprio {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! conta, e não
    transfera sua própria conta para outros sem nossa autorização.

    <br />

    Tentamos o nosso melhor para fazer
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! disponíveis para todos, mas
    você não devem usar {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! in the
    following situations:

    <br />

    1. Menos de 13 anos (ou menor da idade legal mínima para usar nossos
    produtos em seu país/região).

    <br />

    2. Você é um criminoso de sexo condenado

    <br />

    3. Sua conta foi desactivada por nós por violar nossos termos ou políticas

    <br />

    <br />

    Temos o direito de restringir os usuários que violam esses regulamentos de
    usar o APP ou restringir seu acesso. Se você viola sériamente ou
    repetidamente terms of this article; tomando as ações anteriores pode nos
    expor ou outras partes em responsabilidades legais, podem prejudicar
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! usuários, podem danificar
    ou afetar a integridade ou funcionamento de qualquer serviço, sistema ou
    produtos que fornecemos; Limitado por limitações técnicas; ou a lei nos
    proibe de tomar as ações acima mencionadas.

    <br />

    2. Permissão concedida a nós por vocês

    <br />

    Precisamos que nos concedam permissões específicas para fornecer serviços a
    vocês:

    <br />

    1. Investigar ou determinar atividades ilegais ou violações de nossos termos
    e políticas (por exemplo: determinar ou investigar violações de nossa
    produtos ou sistemas);

    <br />

    2. cumprir obrigações legais, como manter evidências;

    <br />

    3. Cumpre com os requisitos dos departamentos judiciais ou administrativos,
    departamentos de aplicação da lei ou agências governamentais;

    <br />

    <br />

    1. Neste caso, o período de retenção do conteúdo relevante não irá excedem o
    período de retenção necessário determinado de acordo com o propósito de
    retenção de conteúdo, e o período exato de retenção depende da situação.

    <br />

    2. Actualiza os permissões do software que você usa ou descarrega: você
    descarrega ou usar nosso software, o que significa conceder uma licença para
    nos permitir descarregar e instalar a atualização para você quando a
    atualização do software é available.

    <br />

    <br />

    3. Termos adicionais

    <br />

    1. Contas congeladas ou suspensas

    <br />

    Esperamos que {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! é um lugar
    onde as pessoas se sentem seguras. Se nós determinam que vocês violaram
    nossos termos ou políticas claramente, severamente, ou repetidamente,
    suspenderemos temporariamente ou desactivaremos permanentemente sua conta.

    <br />

    2. Limitação da responsabilidade

    <br />

    Tentamos o nosso melhor para fornecer aos usuários os melhores produtos e
    fornecer claramente diretrizes para cada usuário. No entanto, nós apenas
    fornecemos produtos como eles são, então não garantimos que os produtos
    continuarão a ser seguros, confiáveis, e sem erros, nem que o funcionamento
    dos produtos não será interrompido, atrasado ou imperfeito. Na medida
    permitida pela lei, nós também recusar a reconhecer todas as garantias
    expressas ou implicadas, incluindo garantias de comerciabilidade, adequação
    para fins específicos, propriedade e não-infracção. Não podemos prever
    quando haverá problemas com produtos. Por conseguinte, nossa
    responsabilidade será limitada ao máximo permitidos pelas leis aplicáveis;
    por qualquer perda de lucro, renda, informação ou dados oriundos ou
    relacionados a essa cláusula ou

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos, ou
    consequênciais, especiais, indiretos, danos disciplinares sexuais, punitivos
    ou colaterais, mesmo que tenhamos sido notificaram que esses danos podem
    ocorrer, não seremos responsáveis.

    <br />

    3. Disputes

    <br />

    We try to provide clear rules to reduce or try to avoid disputes between you
    e nós. No entanto, se uma disputa acontecer, é útil saber antecipadamente
    onde resolver a disputa e a lei aplicável. Se você é um consumidor, as leis
    do seu país de residência se aplicarão a qualquer reclamação, processo ou
    controvérsias (coletivamente denominadas "alegações") levadas contra nós em
    relação a esses termos ou {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!
    produtos, e você pode ter jurisdição sobre esses recursos Qualquer tribunal
    competente no país/região de jurisdição busca uma solução. Em todos os
    outros casos, você concorda que esses alegações só serão resolvidas no
    Tribunal de Distrito dos EUA para o Norte Distrito da Califórnia ou
    tribunais estatais no Condado de São Mateo. Você também concordam que seu
    litigio em relação a qualquer destas alegações está sujeito jurisdição
    pessoal dos tribunais acima mencionados, e essa lei da Califórnia se
    aplicará a esses termos e quaisquer pretensões, independentemente do
    conflito de leis princípios.

    <br />

    1. Menos de 13 anos (ou menor da idade legal mínima para usar nossos
    produtos em seu país/região).

    <br />

    2. Você é um criminoso de sexo condenado

    <br />

    3. Sua conta foi desactivada por nós por violar nossos termos ou políticas

    <br />

    <br />

    Temos o direito de restringir os usuários que violam esses regulamentos de
    usar o APP ou restringir seu acesso. Se você viola sériamente ou
    repetidamente terms of this article; tomando as ações anteriores pode nos
    expor ou outras partes em responsabilidades legais, podem prejudicar
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! usuários, podem danificar
    ou afetar a integridade ou funcionamento de qualquer serviço, sistema ou
    produtos que fornecemos; Limitado por limitações técnicas; ou a lei nos
    proibe de tomar as ações acima mencionadas.

    <br />

    2. Permissão concedida a nós por vocês

    <br />

    Precisamos que nos concedam permissões específicas para fornecer serviços a
    vocês:

    <br />

    1. Investigar ou determinar atividades ilegais ou violações de nossos termos
    e políticas (por exemplo: determinar ou investigar violações de nossa
    produtos ou sistemas);

    <br />

    2. cumprir obrigações legais, como manter evidências;

    <br />

    3. Cumpre com os requisitos dos departamentos judiciais ou administrativos,
    departamentos de aplicação da lei ou agências governamentais;

    <br />

    <br />

    1. Neste caso, o período de retenção do conteúdo relevante não irá excedem o
    período de retenção necessário determinado de acordo com o propósito de
    retenção de conteúdo, e o período exato de retenção depende da situação.

    <br />

    2. Actualiza os permissões do software que você usa ou descarrega: você
    descarrega ou usar nosso software, o que significa conceder uma licença para
    nos permitir descarregar e instalar a atualização para você quando a
    atualização do software é available.

    <br />

    <br />

    3. Termos adicionais

    <br />

    1. Contas congeladas ou suspensas

    <br />

    Esperamos que {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! é um lugar
    onde as pessoas se sentem seguras. Se nós determinam que vocês violaram
    nossos termos ou políticas claramente, severamente, ou repetidamente,
    suspenderemos temporariamente ou desactivaremos permanentemente sua conta.

    <br />

    2. Limitação da responsabilidade

    <br />

    Tentamos o nosso melhor para fornecer aos usuários os melhores produtos e
    fornecer claramente diretrizes para cada usuário. No entanto, nós apenas
    fornecemos produtos como eles são, então não garantimos que os produtos
    continuarão a ser seguros, confiáveis, e sem erros, nem que o funcionamento
    dos produtos não será interrompido, atrasado ou imperfeito. Na medida
    permitida pela lei, nós também recusar a reconhecer todas as garantias
    expressas ou implicadas, incluindo garantias de comerciabilidade, adequação
    para fins específicos, propriedade e não-infracção. Não podemos prever
    quando haverá problemas com produtos. Por conseguinte, nossa
    responsabilidade será limitada ao máximo permitidos pelas leis aplicáveis;
    por qualquer perda de lucro, renda, informação ou dados oriundos ou
    relacionados a essa cláusula ou

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos, ou
    consequênciais, especiais, indiretos, danos disciplinares sexuais, punitivos
    ou colaterais, mesmo que tenhamos sido notificaram que esses danos podem
    ocorrer, não seremos responsáveis.

    <br />

    3. Disputes

    <br />

    We try to provide clear rules to reduce or try to avoid disputes between you
    e nós. No entanto, se uma disputa acontecer, é útil saber antecipadamente
    onde resolver a disputa e a lei aplicável. Se você é um consumidor, as leis
    do seu país de residência se aplicarão a qualquer reclamação, processo ou
    controvérsias (coletivamente denominadas "alegações") levadas contra nós em
    relação a esses termos ou {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!
    produtos, e você pode ter jurisdição sobre esses recursos Qualquer tribunal
    competente no país/região de jurisdição busca uma solução. Em todos os
    outros casos, você concorda que esses alegações só serão resolvidas no
    Tribunal de Distrito dos EUA para o Norte Distrito da Califórnia ou
    tribunais estatais no Condado de São Mateo. Você também concordam que seu
    litigio em relação a qualquer destas alegações está sujeito jurisdição
    pessoal dos tribunais acima mencionados, e essa lei da Califórnia se
    aplicará a esses termos e quaisquer pretensões, independentemente do
    conflito de leis princípios.

    <br />

    4. Outros

    <br />

    1. Esta cláusula (anteriormente conhecida como "Declaração de Direitos e
    Responsabilidade") constitutes the complete agreement between you and

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! regarding the use of our
    products. Esta cláusula substitui qualquer acordo anterior.

    <br />

    2. Alguns produtos que fornecemos também são sujeitos a termos
    suplementares. Se você usar qualquer desses produtos, nós forneceremos
    termos suplementares e eles sejam parte do acordo entre nós e vocês. Por
    exemplo, se você usa nosso produtos para fins comerciais ou comerciais, como
    comprar anúncios, vendendo produtos, desenvolvendo aplicações, gerenciando a
    empresa equipe de gestão ou página de casa pública, ou usando nossa medida
    de eficácia serviço, você deve concordar com nossos termos de negócio. If
    you publish or share conteúdo com música, você deve cumprir nossas
    diretrizes de uso da música. Se alguma termos suplementares conflitam com
    esses termos, os termos suplementares devem prevalecem em relação aos termos
    conflitáveis.

    <br />

    3. Se qualquer parte desta cláusula for considerada inenforcável, a validade
    da as partes restantes não serão afetadas. Nosso fracasso em implementar
    qualquer parte esses termos não deveriam ser considerados como uma renúncia
    a nossos direitos. Any modificação ou renúncia desses termos devem estar por
    escrito e assinados por nós.

    <br />

    4. Sem nosso consentimento, você não pode transferir nenhum direito ou
    obrigação sob essas condições para qualquer terceiro.

    <br />

    5. Você pode designar uma pessoa (chamada de "contato delegado") gerenciar
    sua conta quando se torna uma conta de luto. Só a sua confiança pessoa de
    contato ou pessoa que você concordou explicitamente com uma vontade válida
    ou documento semelhante para revelar seu conteúdo a eles após morte ou
    incapacidade pode pedir a divulgação de conteúdo de conta para eles após sua
    conta se torna uma conta de luto.

    <br />

    Esta cláusula não concede direitos de beneficiários de terceiros. Nós temos
    right to freely transfer all our rights and obligations under these terms
    devido a fusões, aquisições, vendas de ativos ou execução de direitos ou
    outras razões.

    <br />

    Informação do dispositivo

    <br />

    Como descrito abaixo, coletamos informações de computadores, celulares, TVs
    inteligentes, e outros dispositivos de rede que você usa e integra nossos
    produtos, e informação sobre esses dispositivos, e juntar essa informação
    sobre os diferentes dispositivos que você usa. Por exemplo, usamos a
    informação coletada about your use of our products on your mobile phone to
    better customize the conteúdo (incluindo anúncios) que você pode ver quando
    usa nosso produtos em outros dispositivos como laptops ou comprimidos.
    Função, ou avaliar se você vai responder e tomar ação nos anúncios em que
    você vê seu telefone em outros dispositivos.

    <br />

    A informação que obtemos desses dispositivos inclui:

    <br />

    Atributos do dispositivo: como sistema operacional, hardware e versão de
    software, energia da bateria, força do sinal, espaço de armazenamento
    disponível, tipo de navegador, aplicação, nome e tipo de arquivo, e
    informação plug-in.

    <br />

    Operação de dispositivo: Informação sobre as operações e comportamentos
    realizados pelo usuário no dispositivo, como se a janela é configurada como
    o primeiro plano ou o fundo, ou o movimento do rato (isso ajuda distinguish
    between manual operations and machine operations).

    <br />

    Informação de identificação: identificadores únicos, números de dispositivos
    e outros informações de identificação (como informações obtidas dos jogos
    que você jogar, aplicações ou contas que você usa, e números de dispositivos
    domésticos (ou outros informação relacionada a
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!’ s Identificação única
    informação para o produto).

    <br />
    Sinal de dispositivo: sinal Bluetooth e informação sobre Wi-Fi ao redor
    pontos de acesso, sinais e torres de celulares.

    <br />

    Information from device settings: Information that you allow us to receive
    através das configurações do dispositivo que você acende, como acesso ao seu
    GPS localização, câmera ou fotos.

    <br />

    Rede e conexão: Por exemplo, informações como seu móvel nome de operador ou
    ISP, linguagem, zona horaria, número de telefone móvel, endereço IP,
    velocidade de conexão, etc., e em alguns casos, informação sobre outros
    dispositivos em ou perto de sua rede, para que possamos realizar várias
    operações, como ajudar você a lançar vídeos do seu telefone para sua TV.

    <br />

    Dados de cookies: Dados obtidos de cookies armazenados em seu dispositivo,
    incluindo ID e configurações de cookie. Os usuários podem aprender mais
    sobre como usamos biscoitos

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! Política de Cookie.

    <br />

    Informação relacionada ao local: Usamos informação relacionada ao local
    (como your current location, where you live, where you want to go, and
    businesses e pessoas próximas de vocês) para fornecer, personalizar e
    melhorar nossos produtos para vocês e outros (incluindo publicidade).
    Informação relacionada ao local pode ser baseado em: informação precisa
    sobre a localização do dispositivo (se você nos permitir coletar ela),
    endereço IP, e informação sobre você e outros usando

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos (como assinar ou
    participar em eventos).

    <br />

    Product research and development: We use the information we have to develop,
    testar e melhorar nossos produtos, incluindo realizar pesquisas e pesquisas,
    testando e problematizando novos produtos e funções.

    <br />

    Provide effectiveness measurement, analysis and other business services.

    <br />

    Usamos a informação que temos (incluindo suas atividades fora de nossa
    produtos, como websites e anúncios navegados) para ajudar os anúncios e
    outros parceiros avaliam a eficácia e cobertura de seus anúncios e serviços,
    e entender as pessoas que usam tipos de serviços e como as pessoas interagem
    com seus sites, aplicativos e serviços.

    <br />

    Reforçar a segurança dos usuários, a segurança dos dados e a reputação dos
    produtos.

    <br />

    Usamos a informação que temos para verificar contas e atividades, combater
    comportamento nocivo, detectar e prevenir spam e outras experiências ruim,
    manter a credibilidade de nossos produtos, e melhorar a segurança dos
    usuários e os dados segurança dentro e fora
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos. Por exemplo, nós
    usar os dados que temos para investigar atividade suspeita ou violações de
    nossa termos ou políticas, ou para detectar quando os usuários precisam de
    ajuda.

    <br />

    Comuniquem com vocês.

    <br />

    Usaremos a informação que temos para enviar informações de marketing,
    comunicar com vocês sobre nossos produtos, e permitir que vocês entendam
    nossas políticas e termos. When you contact us, we will also use your
    information to provide uma resposta.

    <br />

    Se essa política muda, como vamos avisá-lo?

    <br />

    We will notify you before revising this policy so that you can view the
    política revista antes de escolher continuar usando nossos produtos.

    <br />

    Sinal de dispositivo: sinal Bluetooth e informação sobre Wi-Fi ao redor
    pontos de acesso, sinais e torres de celulares.

    <br />

    Information from device settings: Information that you allow us to receive
    através das configurações do dispositivo que você acende, como acesso ao seu
    GPS localização, câmera ou fotos.

    <br />

    Rede e conexão: Por exemplo, informações como seu móvel nome de operador ou
    ISP, linguagem, zona horaria, número de telefone móvel, endereço IP,
    velocidade de conexão, etc., e em alguns casos, informação sobre outros
    dispositivos em ou perto de sua rede, para que possamos realizar várias
    operações, como ajudar você a lançar vídeos do seu telefone para sua TV.

    <br />

    Dados de cookies: Dados obtidos de cookies armazenados em seu dispositivo,
    incluindo ID e configurações de cookie. Os usuários podem aprender mais
    sobre como usamos biscoitos

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! Política de Cookie.

    <br />

    Informação relacionada ao local: Usamos informação relacionada ao local
    (como your current location, where you live, where you want to go, and
    businesses e pessoas próximas de vocês) para fornecer, personalizar e
    melhorar nossos produtos para vocês e outros (incluindo publicidade).
    Informação relacionada ao local pode ser baseado em: informação precisa
    sobre a localização do dispositivo (se você nos permitir coletar ela),
    endereço IP, e informação sobre você e outros usando

    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos (como assinar ou
    participar em eventos).

    <br />

    Product research and development: We use the information we have to develop,
    testar e melhorar nossos produtos, incluindo realizar pesquisas e pesquisas,
    testando e problematizando novos produtos e funções.

    <br />

    Provide effectiveness measurement, analysis and other business services.

    <br />

    Usamos a informação que temos (incluindo suas atividades fora de nossa
    produtos, como websites e anúncios navegados) para ajudar os anúncios e
    outros parceiros avaliam a eficácia e cobertura de seus anúncios e serviços,
    e entender as pessoas que usam tipos de serviços e como as pessoas interagem
    com seus sites, aplicativos e serviços.

    <br />

    Reforçar a segurança dos usuários, a segurança dos dados e a reputação dos
    produtos.

    <br />

    Usamos a informação que temos para verificar contas e atividades, combater
    comportamento nocivo, detectar e prevenir spam e outras experiências ruim,
    manter a credibilidade de nossos produtos, e melhorar a segurança dos
    usuários e os dados segurança dentro e fora
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}! produtos. Por exemplo, nós
    usar os dados que temos para investigar atividade suspeita ou violações de
    nossa termos ou políticas, ou para detectar quando os usuários precisam de
    ajuda.

    <br />

    Comuniquem com vocês.

    <br />

    Usaremos a informação que temos para enviar informações de marketing,
    comunicar com vocês sobre nossos produtos, e permitir que vocês entendam
    nossas políticas e termos. When you contact us, we will also use your
    information to provide uma resposta.

    <br />

    Se essa política muda, como vamos avisá-lo?

    <br />

    We will notify you before revising this policy so that you can view the
    política revista antes de escolher continuar usando nossos produtos.

    <br />
  </div>

  <div v-if="locale==='tur'" class="box">
    Şartlar
    <br />
    Hoşgeldiniz {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!
    <br />
    tarafından sağlanan teknoloji ve hizmetler
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} insanlara yardımcı olmak için tasarlanmıştır
    etkileşimde bulunun, boş zamanlarını para kazanmak için kullanın, yardım için bir platform sağlayın,
    ve iş geliştirmek. Bu hizmet şartları, kullanımınız için geçerlidir.
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ve diğer ürünler,
    işlevler, uygulamalar, hizmetler, teknolojiler ve yazılımlar (topluca
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} veya ürünler olarak anılır)
    diğer şartları (şartları değil) açıkça belirtmediğimiz sürece tarafımızdan sağlanan
    Bu makalenin) geçerlidir. Bu ürünler tarafından sağlanmaktadır
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}.
    <br />
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} veya diğer kullanımınız
    Bu makalede açıklanan ürün ve hizmetler ücretsizdir. bizim kullanarak
    ürünleri, size gösterebileceğimiz şeyleri kabul ediyorsunuz.
    <br />
    Kişisel bilgilerinizi gizli tutuyoruz ve satmayacağız.
    kişisel verileri başkalarına
    <br />
    <br />
    1. Sağladığımız hizmetler
    <br />
    Misyonumuz daha fazla iş yaratmak, birçok insanın zengin olmasına izin vermek ve fakirlere yardım etmektir.
    insanlar hayatlarını iyileştirir. Size ürün ve hizmetler sunuyoruz:
    <br />
    Hizmetlerimizi iyileştirmenin yollarını araştırın:
    <br />
    geliştirmek, test etmek ve iyileştirmek için araştırma faaliyetlerine katılacağız.
    Ürün:% s. Bu, topladığımız ve anladığımız kullanıcı verilerini analiz etmeyi içerir.
    anketler yapmak, test etmek ve
    yeni özelliklerde sorun giderme. Veri kullanım politikası, verileri nasıl kullandığımızı açıklar
    hizmetlerimizi geliştirmek ve iyileştirmek için araştırmaları desteklemek.
    <br />
    Kullanıcılara küresel ölçekte hizmetlerimizi sağlamak için:
    <br />
    Küresel hizmetleri işletmek için içerik ve verileri depolamamız ve dağıtmamız gerekir.
    dünya çapındaki veri merkezlerinde ve sistemlerde (dünyanın dışındakiler dahil)
    yaşadığınız ülke/bölge). Bu altyapı çalıştırılabilir veya
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Limited veya onun
    bağlı kuruluşlar.
    <br />
    <br />
    2. {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} taahhüdünüz
    <br />
    Misyonumuzu ilerletmek için size ve başkalarına bu hizmetleri sunuyoruz. İçinde
    değişim, aşağıdaki taahhütleri yapmanıza ihtiyacımız var:
    <br />
    1. {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}'i kimler kullanabilir?
    <br />
    İnsanlar gerçek kimlikleriyle faaliyet yürütürken,
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    daha güvenli olacak ve insanlar eylemlerinden daha sorumlu olacak.
    Bu nedenle, her kullanıcıya ihtiyacımız var:
    <br />
    Günlük hayattan isimler kullanın.
    <br />
    Kendiniz hakkında doğru bilgi verin.
    <br />
    Yalnızca bir hesap oluşturun (kişisel kullanım için) ve zaman çizelgenizi yalnızca
    kişisel amaçlar.
    <br />
    Hesap şifrenizi başkalarına açıklamayın, başkalarının kullanmasına izin vermeyin
    kendi {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} hesabınız ve
    kendi hesabınızı iznimiz olmadan başkalarına devretmek.
    <br />
    yapmak için elimizden geleni yapıyoruz
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} herkes tarafından kullanılabilir, ancak
    içinde {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} kullanmamalısınız.
    aşağıdaki durumlar:
    <br />
    1. 13 yaşın altında (veya ürünlerimizi kullanmak için minimum yasal yaşın altında
    ülkenizde/bölgenizde).
    <br />
    2. Hüküm giymiş bir seks suçlususunuz
    <br />
    3. Hesabınız, şartlarımızı veya politikalarımızı ihlal ettiği için tarafımızca devre dışı bırakıldı
    <br />
    <br />
    Bu düzenlemeleri ihlal eden kullanıcıları kısıtlama hakkına sahibiz.
    APP veya erişimlerini kısıtlayın. ciddi şekilde veya tekrar tekrar ihlal ederseniz,
    bu makalenin şartları; yukarıdaki eylemleri yapmak bizi veya diğer
    yasal yükümlülüklere taraf olanlar, zarar verebilir
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} kullanıcıları, zarar verebilir veya etkileyebilir
    sağladığımız herhangi bir hizmet, sistem veya ürünün bütünlüğü veya işleyişi;
    Teknik sınırlamalarla sınırlandırılmıştır; ya da kanun bizim almamızı yasaklıyor.
    bahsi geçen eylemler.
    <br />
    2. Tarafınızdan bize verilen izin
    <br />
    Size hizmet sunabilmemiz için bize belirli izinler vermenize ihtiyacımız var:
    <br />
    1. Yasa dışı faaliyetleri veya şartlarımızın ihlallerini araştırmak veya belirlemek
    ve politikalar (örneğin: ihlalleri belirlemek veya araştırmak)
    ürünler veya sistemler);
    <br />
    2. Kanıtları saklamak gibi yasal yükümlülükleri yerine getirmek;
    <br />
    3. Adli veya idari birimlerin gereklerine uymak,
    kolluk kuvvetleri veya devlet kurumları;
    <br />
    <br />
    1. Bu durumda ilgili içeriğin saklama süresi,
    amacına göre belirlenen gerekli saklama süresini aşmak,
    içerik saklama ve tam saklama süresi duruma bağlıdır.
    <br />
    2. Kullandığınız veya indirdiğiniz yazılımların izinlerini güncelleyin: İndirdiğiniz
    veya yazılımımızı kullanın, bu da bize izin vermek için bize bir lisans vermek anlamına gelir.
    yazılım güncellemesi tamamlandığında güncellemeyi sizin için indirip yükleyin.
    mevcut.
    <br />
    <br />
    3. Ek şartlar
    <br />
    1. Hesabın dondurulması veya askıya alınması
    <br />
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} öğesinin bir yer olduğunu umuyoruz.
    insanların kendilerini güvende hissettikleri yer. Şartlarımızı ihlal ettiğinizi belirlersek veya
    politikaları açıkça, ciddi şekilde veya tekrar tekrar, geçici olarak askıya alacağız veya
    hesabınızı kalıcı olarak devre dışı bırakın.
    <br />
    2. Sorumluluğun Sınırlandırılması
    <br />
    Kullanıcılara en iyi ürünleri sunmak ve netlik sağlamak için elimizden gelenin en iyisini yapıyoruz.
    her kullanıcı için yönergeler. Ancak, ürünleri yalnızca oldukları gibi sunuyoruz, bu nedenle
    ürünlerin güvenli, güvenilir olmaya devam edeceğini garanti etmiyoruz,
    ve hatasız, ne de ürünlerin çalışması
    kesintiye uğramış, gecikmiş veya kusurlu. Yasaların izin verdiği ölçüde, biz de
    zımni dahil tüm açık veya zımni garantileri kabul etmeyi reddetmek
    satılabilirlik garantileri, belirli amaçlara uygunluk, mülkiyet
    ve ihlal etmeme. Sorunlarımızın ne zaman olacağını tahmin edemeyiz.
    Ürün:% s. Buna göre, sorumluluğumuz azami ölçüde sınırlı olacaktır.
    geçerli yasaların izin verdiği; herhangi bir kar, gelir, bilgi veya
    bu maddeden kaynaklanan veya bu maddeyle ilgili veriler veya
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ürün veya sonuç,
    özel, dolaylı, disipline yönelik cinsel, cezai veya tali zararlar, hatta
    bu tür zararların meydana gelebileceği konusunda bilgilendirildiysek, sorumlu olmayacağız.
    <br />
    3. Anlaşmazlıklar
    <br />
    Aranızdaki anlaşmazlıkları azaltmak veya önlemek için net kurallar sağlamaya çalışıyoruz.
    ve biz. Ancak, bir anlaşmazlık olursa, önceden bilmek yardımcı olur
    anlaşmazlığın nerede çözüleceği ve geçerli yasa. Tüketici iseniz,
    herhangi bir iddia, dava için ikamet ettiğiniz ülkenin yasaları geçerli olacaktır.
    veya bize karşı açılan anlaşmazlıklar (topluca "talepler" olarak anılacaktır)
    bu terimlerle ilgili veya
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ürün ve sahip olabilirsiniz
    bu iddialar üzerinde yargı yetkisi bulunduğu ülkedeki/bölgedeki herhangi bir yetkili mahkeme
    yargı çözüm arar. Diğer tüm durumlarda, bunların
    iddialar yalnızca ABD Kuzey Bölge Mahkemesinde çözülecektir.
    Kaliforniya Bölgesi veya San Mateo İlçesindeki eyalet mahkemeleri. Ayrıca
    bu iddialardan herhangi biriyle ilgili davanızın aşağıdakilere tabi olduğunu kabul edersiniz.
    yukarıda belirtilen mahkemelerin kişisel yargı yetkisi ve Kaliforniya yasaları
    kanunlar ihtilafından bağımsız olarak bu şartlar ve tüm talepler için geçerli olacaktır.
    prensipler.
    <br />
    4. Diğer
    <br />
    1. Bu madde (eskiden "Hak ve Sorumluluk Bildirimi" olarak biliniyordu)
    aranızdaki tam sözleşmeyi teşkil eder ve
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    Ürün:% s. Bu madde önceki herhangi bir anlaşmanın yerine geçer.
    <br />
    2. Sağladığımız bazı ürünler de ek koşullara tabidir. Eğer sen
    bu ürünlerden herhangi birini kullanırsanız, ek şartlar sağlayacağız ve bunlar
    sizinle aramızdaki anlaşmanın bir parçası olun. Örneğin, bizim
    reklam satın almak gibi ticari veya iş amaçlı ürünler,
    ürün satışı, uygulama geliştirme, şirketinizin yönetimi
    yönetim ekibi veya genel ana sayfa veya etkinlik ölçümümüzü kullanma
    hizmet, iş şartlarımızı kabul etmelisiniz. yayınlarsanız veya paylaşırsanız
    müzik içeren içerik, müzik kullanım yönergelerimize uymalısınız. Varsa
    ek şartlar bu şartlarla çelişirse, ek şartlar
    çelişen şartlara göre geçerli olacaktır.
    <br />
    3. Bu maddenin herhangi bir kısmı uygulanamaz kabul edilirse,
    kalan parçalar etkilenmeyecektir. Herhangi bir bölümünü uygulamadaki başarısızlığımız
    bu şartlar, haklarımızdan feragat olarak değerlendirilmemelidir. Hiç
    bu şartların değiştirilmesi veya feragat edilmesi yazılı olarak yapılmalı ve tarafımızca imzalanmalıdır.
    <br />
    4. Rızamız olmadan, sözleşme kapsamındaki herhangi bir hak veya yükümlülüğü devredemezsiniz.
    bu şartları herhangi bir üçüncü tarafa
    <br />
    5. Bir kişiyi ("yetkilendirilen kişi" olarak anılır) atayabilirsiniz.
    Bir yas hesabı olduğunda hesabınızı yönetin. sadece sana emanet
    irtibat kişisi veya geçerli bir vasiyetnamede açıkça kabul ettiğiniz kişi veya
    içeriğinizi ölüm veya iş göremezlikten sonra onlara ifşa etmek için benzer bir belge
    hesabınızdan sonra hesap içeriğinin kendilerine ifşa edilmesini talep edebilir
    yas hesabı olur.
    <br />
    Bu madde, herhangi bir üçüncü taraf lehtar hakkı vermez. biz var
    bu şartlar altındaki tüm hak ve yükümlülüklerimizi serbestçe devretme hakkı
    birleşmeler, satın almalar, varlık satışları veya yasal veya diğer yaptırımların uygulanması nedeniyle
    sebepler.
    <br />
    Cihaz bilgisi
    <br />
    Aşağıda açıklandığı gibi, bilgisayarlardan, cep telefonlarından bilgi topluyoruz,
    kullandığınız ve entegre ettiğiniz akıllı TV'ler ve diğer ağ bağlantılı cihazlar
    ürünler ve bu cihazlarla ilgili bilgiler ve bu bilgileri
    kullandığınız farklı cihazlar. Örneğin, toplanan bilgileri kullanıyoruz
    daha iyi özelleştirmek için ürünlerimizi cep telefonunuzda kullanımınız hakkında
    sitemizi kullandığınızda görebileceğiniz içerik (reklamlar dahil)
    dizüstü bilgisayarlar veya tabletler gibi diğer cihazlardaki ürünler. işlev veya
    Gördüğünüz reklamlara yanıt verip vermeyeceğinizi ve eyleme geçip geçmeyeceğinizi değerlendirin
    Telefonunuzu diğer cihazlarda
    <br />
    Bu cihazlardan elde ettiğimiz bilgiler şunları içerir:
    <br />
    Cihaz özellikleri: işletim sistemi, donanım ve yazılım sürümü gibi,
    pil gücü, sinyal gücü, kullanılabilir depolama alanı, tarayıcı türü,
    uygulama ve dosya adı ve türü ve eklenti bilgileri.
    <br />
    Cihaz işlemi: Gerçekleştirilen işlemler ve davranışlar hakkında bilgi
    pencere olarak ayarlanıp ayarlanmadığı gibi cihazdaki kullanıcı tarafından
    ön plan veya arka plan veya farenin hareketi (bu yardımcı olur)
    manuel işlemler ile makine işlemleri arasında ayrım yapın).
    <br />
    Kimlik bilgileri: benzersiz tanımlayıcılar, cihaz numaraları ve diğer
    kimlik bilgileri (sizin oynadığınız oyunlardan elde edilen bilgiler gibi)
    oyun, kullandığınız uygulamalar veya hesaplar) ve ev cihazı numaraları (veya diğer
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ile ilgili bilgiler
    Ürün için benzersiz tanımlama bilgileri).
    <br />
    Cihaz sinyali: Bluetooth sinyali ve çevreleyen Wi-Fi hakkında bilgi
    erişim noktaları, işaretçiler ve cep telefonu kuleleri.
    <br />
    Cihaz ayarlarından gelen bilgiler: Almamıza izin verdiğiniz bilgiler
    GPS'inize erişim gibi açtığınız cihaz ayarları aracılığıyla
    konum, kamera veya fotoğraflar.
    <br />
    Ağ ve bağlantı: Örneğin, cep telefonunuz gibi bilgiler
    operatör veya ISS adı, dil, saat dilimi, cep telefonu numarası, IP adresi,
    bağlantı hızı vb. ve bazı durumlarda diğer cihazlarla ilgili bilgiler
    ağınızın üzerinde veya yakınında, çeşitli işlemleri gerçekleştirebilmemiz için,
    telefonunuzdan TV'nize video yayınlamanıza yardımcı olmak gibi.
    <br />
    Çerez verileri: Aşağıdakiler dahil olmak üzere cihazınızda depolanan çerezlerden elde edilen veriler
    çerez kimliği ve ayarları. Kullanıcılar, çerezleri nasıl kullandığımız hakkında daha fazla bilgi edinebilir.
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Çerez Politikası.
    <br />
    Konumla ilgili bilgiler: Konumla ilgili bilgileri kullanırız (ör.
    bulunduğunuz yer, yaşadığınız yer, gitmek istediğiniz yer ve işletmeler
    ve yakınınızdaki kişiler) ürünlerimizi size sağlamak, özelleştirmek ve geliştirmek için
    ve diğerleri (reklam dahil). Konumla ilgili bilgiler şunlar olabilir:
    aşağıdakilere dayalıdır: doğru cihaz konumu bilgileri (eğer toplamamıza izin verirseniz)
    o), IP adresi ve siz ve kullanan diğer kişiler hakkındaki bilgiler
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
    ürünler (oturum açmak veya etkinliklere katılmak gibi).
    <br />
    Ürün araştırma ve geliştirme: Geliştirmemiz gereken bilgileri kullanırız,
    Anket ve araştırma yapmak da dahil olmak üzere ürünlerimizi test etmek ve geliştirmek,
    yeni ürünleri ve işlevleri test etme ve sorun giderme.
    <br />
    Etkinlik ölçümü, analizi ve diğer iş hizmetleri sağlayın.
    <br />
    Sahip olduğumuz bilgileri kullanırız (bizim dışımızdaki faaliyetleriniz dahil
    reklamverenlere yardımcı olmak için web siteleri ve göz atılan reklamlar gibi ürünler
    ve diğer ortaklar, çalışmalarının etkinliğini ve kapsamını değerlendirir.
    reklamları ve hizmetleri kullanan kişileri anlamak ve
    hizmet türleri ve insanların web siteleri, uygulamaları ve
    Hizmetler.
    <br />
    Kullanıcı güvenliğini, veri güvenliğini ve ürün itibarını güçlendirin.
    <br />
    Hesapları ve faaliyetleri doğrulamak, mücadele etmek için sahip olduğumuz bilgileri kullanırız.
    zararlı davranışlar, spam ve diğer kötü deneyimleri tespit etmek ve önlemek,
    ürünlerimizin güvenilirliğini korumak ve kullanıcı güvenliğini ve verilerini geliştirmek
    iç ve dış güvenlik
    {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ürünleri. Örneğin, biz
    şüpheli faaliyetleri veya ihlalleri araştırmak için elimizdeki verileri kullanmak
    şartlar veya politikalar veya kullanıcıların ne zaman yardıma ihtiyacı olduğunu tespit etmek için.
    <br />
    Sizinle iletişim kurun.
    <br />
    Size pazarlama bilgilerini göndermek için sahip olduğumuz bilgileri kullanacağız,
    ürünlerimiz hakkında sizinle iletişim kurmak ve politikalarımızı anlamanıza izin vermek
    ve terimler. Bizimle iletişime geçtiğinizde, bilgilerinizi sağlamak için de kullanacağız.
    cevap.
    <br />
    Bu politika değişirse, sizi nasıl bilgilendireceğiz?
    <br />
    Bu politikayı gözden geçirmeden önce sizi bilgilendireceğiz, böylece
    ürünlerimizi kullanmaya devam etmeyi seçmeden önce revize edilmiş politika.
    <br />
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  setup() {
    const { t,locale } = useI18n();

    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
