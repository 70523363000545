<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fa">
        <li class="left">
          <router-link to="/login">
            <i class="iconfont icon-fanhui c-999 icon-fanhui"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main main_password log_in">
      <h1 class="reg_title">
        {{ t("common.title.forgotPassword") }}
        <span>{{
          sysDataDictionaryWbObj?.WZMC?.dataContent + " " + t("site.slogan")
        }}</span>
      </h1>
      <div class="box">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="blur"
          @submit.prevent="handleSubmit"
          autocomplete="off"
        >
          <ul>
            <li class="li-code">
              <b>
                {{
                  "+" +
                    (sysDataDictionaryWbObj.ZCQHPZ &&
                      sysDataDictionaryWbObj.ZCQHPZ.dataContent) || ""
                }}
              </b>
              <AFormItem name="mobile">
                <AInput
                  v-model:value="formModel.mobile"
                  :maxlength="15"
                  :placeholder="t('account.placeholder.mobile')"
                />
              </AFormItem>
              <button
                v-if="cooldown <= 0"
                @click="sendCode(formModel.mobile)"
                type="button"
              >
                {{ t("common.button.getVerificationCode") }}
              </button>
              <button v-if="cooldown > 0" disabled="disabled" type="button">
                {{ t("common.button.coolDown", { second: cooldown }) }}
              </button>
            </li>
            <li>
              <AFormItem name="identifyCode">
                <AInput
                  v-model:value="formModel.identifyCode"
                  :maxlength="6"
                  :placeholder="t('account.placeholder.verificationCode')"
                />
              </AFormItem>
            </li>
            <li>
              <AFormItem name="password">
                <AInput
                  type="password"
                  v-model:value="formModel.password"
                  :placeholder="t('account.placeholder.password')"
                />
              </AFormItem>
            </li>
            <li>
              <AFormItem name="checkPass">
                <AInput
                  type="password"
                  v-model:value="formModel.checkPass"
                  :placeholder="t('account.placeholder.confirmPassword')"
                />
              </AFormItem>
            </li>
          </ul>
          <AFormItem>
            <div class="Sys_submit_btn reset_password mt-40 mb-40">
              <button type="submit">{{ t("common.button.confirm") }}</button>
            </div>
          </AFormItem>
        </AForm>
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import api from "@/api";
import { useI18n } from "@/lang";
import { RE_PHONE_NUMBER } from "@/utils/constant";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import Loading from "@/components/Loading.vue";
import { message } from "ant-design-vue";
export default {
  components: {
    Loading,
  },
  setup() {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const formRef = ref(null);
    const formModel = reactive({
      mobile: null,
      password: null,
      checkPass: null,
      identifyCode: null,
    });
    const loading = ref(false);
    let isSend = ref(false);
    let visible = ref(false);
    let cooldown = ref(0);
    let checkMobile = ref(true);
    const handleOk = () => {};

    function handleConfirm() {
      visible.value = false;
    }

    const validatePass = async (rule, value) => {
      if (value === "") {
        return Promise.reject(t("error.validator.checkPass.require"));
      } else if (value !== formModel.password) {
        return Promise.reject(t("error.validator.checkPass.notMatch"));
      } else {
        return Promise.resolve();
      }
    };

    const ruleRef = reactive({
      mobile: [
        {
          required: true,
          message: t("error.validator.mobile.required"),
          trigger: "change",
        },
        {
          validator: async (rule, value) => {
            const check = RE_PHONE_NUMBER.test(value);
            checkMobile.value = !check;
            if(!value){
              return Promise.resolve();
            }
            if (value?.[0] === "0") {
              return Promise.reject(t("error.validator.mobile.startWith0"));
            }
            if (check) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("error.validator.mobile.invalid"));
            }
          },
        },
      ],
      identifyCode: [
        {
          required: true,
          message: t("error.validator.identifyCode.required"),
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: t("error.validator.password.required"),
          trigger: "change",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
      checkPass: [
        {
          validator: validatePass,
        },
        {
          required: true,
          message: t("error.validator.checkPass.required"),
          trigger: "change",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
    });

    async function sendIdentifyCode(mobile) {
      let res = await api.sendSms(
        sysDataDictionaryWbObj.value.ZCQHPZ?.dataContent + mobile
      );
      if (!(res && res.data && res.data.success)) {
        return;
      }
      let sendIdentifyCode = res.data;
      cooldown.value = res.data.data;

      return sendIdentifyCode.success
        ? Promise.resolve()
        : Promise.reject(t("account.signup.smsFailed"));
    }

    function sendCode(mobile) {
      if (!RE_PHONE_NUMBER.test(mobile)) {
        return;
      }
      if (mobile[0] === "0") {
        message.error(t("error.validator.mobile.startWith0"));
        return;
      }
      sendIdentifyCode(mobile).then(() => {
        isSend.value = true;
        let cooldownTimer = setInterval(() => {
          cooldown.value--;
          if (cooldown.value <= 0) {
            clearInterval(cooldownTimer);
          }
        }, 1000);
      });
    }

    async function handleSubmit() {
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      let reqData = {
        passWord: formModel.password,
        smsCode: formModel.identifyCode,
        userAccount:
          sysDataDictionaryWbObj.value.ZCQHPZ?.dataContent + formModel.mobile,
      };
      try {
        loading.value = true;
        let res = await api.forgotPassword(reqData);
        if (res && res.data && res.data.success) {
          await router.replace("/login");
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      visible,
      handleOk,
      handleConfirm,
      formRef,
      formModel,
      ruleRef,
      handleSubmit,
      checkMobile,
      sendCode,
      cooldown,
      sysDataDictionaryWbObj,
      loading,
    };
  },
};
</script>
